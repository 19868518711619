<template>
  <div class="projectSet">
    <div class="projectSet-right">
      <div class="projectSet-right-cont">
        <div class="projectSet-right-cont-head">
          <div class="projectSet-right-cont-head-list" v-if="0">
            <span style="width:130px;">选择门店：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="projectSet-right-cont-head-list">
            <!-- <span>搜索：</span> -->
            <el-input
              placeholder="请输入搜索关键字"
              v-model="input"
              clearable
              @input="inputs"
              @clear="clears"
              class="input radius"
              style="width:100%"
            >
            </el-input>
          </div>
          <div class="projectSet-right-cont-head-list">
            <span>分类：</span>
            <el-select
              v-model="value"
              placeholder="请选择项目分类"
              class="select"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="projectSet-right-cont-head-list">
            <span>状态：</span>
            <el-select
              v-model="options3_value"
              placeholder="请选择项目状态"
              class="select"
            >
              <el-option
                v-for="item in options3"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="projectSet-right-cont-head-list"
            style="margin-left:10px;"
          >
            <el-button
              type="primary"
              style="width:100px"
              @click="searchs"
              icon="el-icon-search"
            >
              查询
            </el-button>
          </div>
          <div class="projectSet-right-cont-head-list">
            <el-button
              type="danger"
              style="width:120px"
              plain
              icon="el-icon-plus"
              @click="edit(1)"
              >添加项目</el-button
            >
          </div>
        </div>
        <el-table
          :data="userSearchData"
          :border="$table('border')"
          :stripe="$table('stripe')"
          :header-cell-style="{ background: $table('bgcolor') }"
          style="width: 100%"
        >
          <!-- <el-table-column prop="id" label="序号" :width="$table('width')"> -->
          <el-table-column prop="id" label="序号" min-width="60">
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="picture" label="主图" min-width="90">
            <template slot-scope="scope">
              <div class="projectSet-photo">
                <img
                  :src="scope.row.picture"
                  :alt="scope.row.name"
                  v-if="scope.row.picture"
                />
                <img
                  src="../../assets/none.png"
                  :alt="scope.row.name"
                  v-if="!scope.row.picture"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="项目名称" min-width="180">
          </el-table-column>
          <el-table-column prop="price" label="价格(￥)" min-width="120"></el-table-column>
          <el-table-column prop="type" label="项目分类" min-width="120">
            <template slot-scope="scope">{{
              getworktype_name(scope.row.category_id, options) || "未配置分类"
            }}</template>
          </el-table-column>
          <el-table-column prop="department" label="归属部门" min-width="120">
            <template slot-scope="scope">{{
              scope.row.dep ? scope.row.dep.name : "未归属部门"
            }}</template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">上架中</span>
              <span style="color: #bbbbbb;" v-if="scope.row.status == 0"
                >已下架</span
              >
            </template>
          </el-table-column>
          <el-table-column label="编辑" fixed="right" min-width="150">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(0, scope)"
                  >编辑</el-button
                >
                <el-button
                  type="info"
                  plain
                  size="small"
                  @click="del(scope)"
                  v-if="!(scope.row.status - 0)"
                  >上架</el-button
                >
                <el-button
                  type="danger"
                  plain
                  size="small"
                  @click="del(scope)"
                  v-if="scope.row.status - 0"
                  >下架</el-button
                >
                <!-- <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="delivery(scope)"
                  >分发</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="projectSet-right-cont-page" v-if="0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[30, 50, 80, 100]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="100"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-row>
      <el-col :span="24" style="overflow: scroll;">
        <el-dialog
          :title="type ? '新增项目' : '修改项目'"
          :visible.sync="dialogVisible"
          width="1200px"
          :close-on-click-modal="false"
        >
          <div class="projectSet-edit" v-if="0">
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-item">
                <span>项目名称</span>
                <el-input
                  placeholder="请输入内容"
                  v-model="formLabelAlign.name"
                  clearable
                >
                </el-input>
              </div>
              <div class="projectSet-edit-list-item">
                <span>项目分类：</span>
                <el-select
                  v-model="formLabelAlign.category_id"
                  style="width: 100%;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="projectSet-edit-list-item">
                <span>归属部门：</span>
                <el-select
                  v-model="formLabelAlign.dep_id"
                  style="width: 100%;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-item">
                <span style="width:115px;">标准价格：</span>
                <el-input-number
                  style="width: 243px;margin-left: -16px;"
                  v-model="formLabelAlign.price"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="999999"
                ></el-input-number>
              </div>
              <div class="projectSet-edit-list-item">
                <span style="width:115px;">体验价格：</span>
                <el-input-number
                  style="width: 243px;margin-left: -16px;"
                  v-model="formLabelAlign.first_price"
                  :precision="2"
                  :min="0"
                  :step="0.1"
                  :max="999999"
                ></el-input-number>
              </div>
              <div class="projectSet-edit-list-item">
                <span style="width:115px;">会员价：</span>
                <el-input-number
                  style="width: 170px;margin-left: -16px;"
                  v-model="formLabelAlign.member_price"
                  :disabled="!formLabelAlign.member_price_status"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="999999"
                ></el-input-number>
                <el-checkbox
                  style="margin-left: 10px;"
                  v-model="formLabelAlign.member_price_status"
                  >启用</el-checkbox
                >
              </div>
            </div>
            <div class="projectSet-edit-list">
              <span style="margin-right:15px;">项目主图：</span>

              <el-image
                v-if="formLabelAlign.picture"
                style="width: 148px; height: 148px"
                :src="formLabelAlign.picture"
                :preview-src-list="[formLabelAlign.picture]"
              >
              </el-image>

              <el-upload
                action="/api/app/meiye/upload/shop_photo"
                class="avatar-uploader"
                :show-file-list="false"
                :http-request="update"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-item">
                <span style="width:115px;">服务时长：</span>
                <el-input-number
                  style="width: 170px;margin-left: -16px;"
                  v-model="formLabelAlign.time_long"
                  :step="1"
                  :min="1"
                  :max="999999"
                ></el-input-number>
                <span style="margin-left:10px;"> 分钟</span>
              </div>
              <div class="projectSet-edit-list-item">
                <span>停止销售：</span>
                <el-radio v-model="formLabelAlign.status" label="1">否</el-radio>
                <el-radio v-model="formLabelAlign.status" label="0">是</el-radio>
              </div>
            </div>
            <div class="projectSet-edit-list" v-if="0">
              <div class="projectSet-edit-list-item">
                <span style="width:100px;">小程序配置：</span>
                <el-checkbox v-model="formLabelAlign.wxapp"
                  >启用小程序
                </el-checkbox>
                <el-checkbox
                  v-model="formLabelAlign.wxstatus"
                  :disabled="!formLabelAlign.wxapp"
                  >启用小程序预约</el-checkbox
                >
              </div>
            </div>
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-item">
                <span style="width:100px;">预约配置：</span>
                <el-checkbox v-model="formLabelAlign.yuyue">网络预约 </el-checkbox>
              </div>
            </div>
            <div class="projectSet-edit-list" v-if="0">
              <div class="projectSet-edit-list-item">
                <span style="width:100px;">可选服务岗位：</span>
                <el-select
                  v-model="formLabelAlign.work_peopel"
                  style="width: 20%;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="projectSet-edit-list" style="display:block;">
              <div class="projectSet-edit-list-item">
                <span style="width:100px;">提成设置：</span>
              </div>
              <div v-for="(item, index) in formLabelAlign.commisions" :key="index">
                <div>
                  <div class="projectSet-edit-list-item" v-if="item.status">
                    <el-popconfirm
                      title="确定删除吗？"
                      @onConfirm="delcommisions(index)"
                    >
                      <button
                        slot="reference"
                        class="projectSet-edit-list-item-btn"
                      ></button>
                    </el-popconfirm>
                    <span style="width:100px;">选择工种：</span>

                    <el-select
                      v-model="formLabelAlign.commisions[index].worktype_id"
                      placeholder="请选择工种"
                      @change="change_commisions"
                    >
                      <el-option
                        v-for="item in options2"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div
                  style="display: flex;align-items: center;justify-content: center;"
                >
                  <div class="projectSet-edit-list-item" v-if="item.status">
                    <span
                      v-if="!formLabelAlign.commisions[index].type"
                      style="width:115px;"
                      >固定提成金额(元)：</span
                    >
                    <span
                      v-if="formLabelAlign.commisions[index].type"
                      style="width:115px;"
                      >百分比提成(%)：</span
                    >
                    <el-input-number
                      style="width: 235px;margin-left: -10px;"
                      v-model="formLabelAlign.commisions[index].amount"
                      :precision="2"
                      :min="0"
                      :step="0.1"
                      :max="999999"
                    ></el-input-number>
                  </div>
                  <div class="projectSet-edit-list-item" v-if="item.status">
                    <span style="width:115px;">提成类型：</span>
                    <el-switch
                      v-model="formLabelAlign.commisions[index].type"
                      active-text="百分比提成"
                      inactive-text="固定提成"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <el-button
                  type="primary"
                  @click="addcommisions"
                  icon="el-icon-circle-plus-outline"
                  >添加提成类型</el-button
                >
              </div>
            </div>

            <div class="projectSet-edit-list" style="display:block;">
              <div
                class="projectSet-edit-list-item"
                style="width:33.33%;height:60px;"
              >
                项目描述：
              </div>
              <!-- <vue-ueditor-wrap
                style="z-index:0;"
                v-model="formLabelAlign.remark"
                :config="config"
              /> -->
              <i-quill-editor style="z-index: 0" :content="formLabelAlign.remark" @textChange="handleTextChange"></i-quill-editor>
            </div>
          </div>

          <div class="projectSet-edit">
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-img">
                <el-image
                  v-if="formLabelAlign.picture"
                  style="width: 160px; height: 160px;"
                  :src="formLabelAlign.picture"
                  :preview-src-list="[formLabelAlign.picture]"
                >
                </el-image>
                <el-image
                  v-if="!formLabelAlign.picture"
                  style="width: 160px; height: 160px;"
                  :src="require('../../assets/none.png')"
                  :preview-src-list="[require('../../assets/none.png')]"
                >
                </el-image>

                <el-upload
                  action="/api/app/meiye/upload/shop_photo"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :http-request="update"
                >
                  <el-button
                    size="small"
                    type="primary"
                    style="margin:15px 0;"
                    v-if="type"
                    >上传主图</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    style="margin:15px 0;"
                    v-if="!type"
                    >更换主图</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    <p>建议上传正方形尺寸大小</p>
                    <p>小于500kb，200x200像素最佳</p>
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-item">
                <span>项目名称：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-input
                    placeholder="请输入内容"
                    v-model="formLabelAlign.name"
                    clearable
                  >
                  </el-input>
                </div>
              </div>

              <div class="projectSet-edit-list-item">
                <span>标准价格：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-input-number
                    style="width: 100%;"
                    v-model="formLabelAlign.price"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :max="999999"
                  ></el-input-number>
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <span>体验价格：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-input-number
                    style="width: 100%;"
                    v-model="formLabelAlign.first_price"
                    :precision="2"
                    :min="0"
                    :step="0.1"
                    :max="999999"
                  ></el-input-number>
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <span>会员价格：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-input-number
                    style="width: 340px;"
                    v-model="formLabelAlign.member_price"
                    :disabled="!formLabelAlign.member_price_status"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :max="999999"
                  ></el-input-number>
                  <el-checkbox
                    style="margin-left: 10px;"
                    v-model="formLabelAlign.member_price_status"
                    >启用</el-checkbox
                  >
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <span>服务时长：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-input-number
                    style="width: 340px;"
                    v-model="formLabelAlign.time_long"
                    :step="1"
                    :min="1"
                    :max="999999"
                  ></el-input-number>
                  <span style="margin-left:10px;"> 分钟</span>
                </div>
              </div>
              <div class="projectSet-edit-list-item projectSet-edit-list-item-special">
                <span>成本：</span>
                <div class="projectSet-edit-list-item-input projectSet-edit-list-item-special-input">
                  <el-input-number
                    style="width: 340px;"
                    v-model="formLabelAlign.cb"
                    :disabled="!formLabelAlign.cb_status"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :max="999999"
                  ></el-input-number>
                  <el-checkbox
                    style="margin-left: 10px;"
                    v-model="formLabelAlign.cb_status"
                    >若项目是百分比提成，需减去该成本</el-checkbox
                  >
                </div>
              </div>
            </div>
            <div class="projectSet-edit-list">
              <div class="projectSet-edit-list-item">
                <span>项目分类：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-select
                    v-model="formLabelAlign.category_id"
                    style="width: 100%;"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <span>归属部门：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-select
                    v-model="formLabelAlign.dep_id"
                    filterable
                    style="width: 100%;"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options1"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <span>是否上架：</span>
                <div class="projectSet-edit-list-item-input">
                  <el-radio v-model="formLabelAlign.status" label="1"
                    >上架</el-radio
                  >
                  <el-radio v-model="formLabelAlign.status" label="0"
                    >下架</el-radio
                  >
                </div>
              </div>
              <div class="projectSet-edit-list-item">
                <span>网络预约：</span>
                <div class="projectSet-edit-list-item-input">
                  <!-- {{formLabelAlign.yuyue}} -->
                  <el-checkbox v-model="formLabelAlign.yuyue">
                    <span v-if="formLabelAlign.yuyue">允许</span>
                    <span v-if="!formLabelAlign.yuyue">不允许</span>
                  </el-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="projectSet-edit-other">
            <div class="projectSet-edit-other-title">提成设置</div>
            <div class="projectSet-edit-other-cont"  :class="commision_status?'projectSet-edit-other-cont-open':'projectSet-edit-other-cont-none'">
              <!-- <div class="projectSet-edit-other-cont-btn" v-if="formLabelAlign.commisions&&formLabelAlign.commisions.length">
                <el-button v-if="!commision_status" @click="commision_status=!commision_status" type="primary" plain>展开提成列表<i class="el-icon-d-arrow-right" style="transform:rotate(90deg);"></i></el-button>
                <el-button v-if="commision_status" @click="commision_status=!commision_status" type="primary" plain>收起提成列表<i class="el-icon-d-arrow-left" style="transform:rotate(90deg);"></i></el-button>
              </div> -->
              <div class="zd_amount-box" :class="commision_status?'zd_amount-box-open':''">
                <div
                  v-for="(item, index) in formLabelAlign.commisions"
                  :key="index"
                  class="zd_amount"
                >
                  <div
                    style="width:300px;display:flex;align-items: center;justify-content: flex-start;"
                  >
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:100%;display:flex;align-items: center;justify-content: flex-start;"
                    >
                      <span style="width:120px;text-align:right"
                        >选择职位/工种：</span
                      >
                      <el-select
                        v-model="formLabelAlign.commisions[index].worktype_id"
                        filterable
                        placeholder="请选择职位/工种"
                        @change="change_commisions"
                      >
                        <el-option
                          v-for="item in options2"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div style="width:300px;">
                    <p>现金</p>
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:auto;"
                    >
                      <span style="width:120px;text-align:right">非指定：</span>

                      <div class="nums">
                        <div class="nums-input">
                          <el-input-number
                            v-model="formLabelAlign.commisions[index].amount"
                            controls-position="right"
                            :precision="2"
                            :step="0.1"
                            :min="0"
                            :max="9999999"
                          ></el-input-number>
                        </div>
                        <div class="nums-select">
                          <el-select
                            v-model="formLabelAlign.commisions[index].type"
                            placeholder="请选择提成类型"
                          >
                            <el-option
                              v-for="item in zd_amount"
                              :key="item.label"
                              :label="item.value"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>

                      <!-- <el-switch
                      v-model="formLabelAlign.commisions[index].type"
                      active-text="百分比提成"
                      inactive-text="固定提成"
                    >
                    </el-switch> -->
                    </div>

                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:auto;"
                    >
                      <span style="width:120px;text-align:right">指定：</span>
                      <div class="nums">
                        <div class="nums-input">
                          <el-input-number
                            v-model="formLabelAlign.commisions[index].zd_amount"
                            controls-position="right"
                            :precision="2"
                            :step="0.1"
                            :min="0"
                            :max="9999999"
                          ></el-input-number>
                        </div>
                        <div class="nums-select">
                          <el-select
                            v-model="formLabelAlign.commisions[index].zd_type"
                            placeholder="请选择提成类型"
                          >
                            <el-option
                              v-for="item in zd_amount"
                              :key="item.label"
                              :label="item.value"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:230px;">
                    <p>储蓄卡</p>
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:auto;"
                    >
                      <div class="nums">
                        <div class="nums-input">
                          <el-input-number
                            v-model="formLabelAlign.commisions[index].value_amount"
                            controls-position="right"
                            :precision="2"
                            :step="0.1"
                            :min="0"
                            :max="9999999"
                          ></el-input-number>
                        </div>
                        <div class="nums-select">
                          <el-select
                            v-model="formLabelAlign.commisions[index].value_type"
                            placeholder="请选择提成类型"
                          >
                            <el-option
                              v-for="item in zd_amount"
                              :key="item.label"
                              :label="item.value"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:auto;"
                    >
                      <div class="nums">
                        <div class="nums-input">
                          <el-input-number
                            v-model="
                              formLabelAlign.commisions[index].zd_value_amount
                            "
                            controls-position="right"
                            :precision="2"
                            :step="0.1"
                            :min="0"
                            :max="9999999"
                          ></el-input-number>
                        </div>
                        <div class="nums-select">
                          <el-select
                            v-model="formLabelAlign.commisions[index].zd_value_type"
                            placeholder="请选择提成类型"
                          >
                            <el-option
                              v-for="item in zd_amount"
                              :key="item.label"
                              :label="item.value"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:230px;">
                    <p>疗程</p>
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:auto;"
                    >
                      <div class="nums">
                        <div class="nums-input">
                          <el-input-number
                            v-model="formLabelAlign.commisions[index].treat_amount"
                            controls-position="right"
                            :precision="2"
                            :step="0.1"
                            :min="0"
                            :max="9999999"
                          ></el-input-number>
                        </div>
                        <div class="nums-select">
                          <el-select
                            v-model="formLabelAlign.commisions[index].treat_type"
                            placeholder="请选择提成类型"
                          >
                            <el-option
                              v-for="item in zd_amount"
                              :key="item.label"
                              :label="item.value"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:auto;"
                    >
                      <div class="nums">
                        <div class="nums-input">
                          <el-input-number
                            v-model="
                              formLabelAlign.commisions[index].zd_treat_amount
                            "
                            controls-position="right"
                            :precision="2"
                            :step="0.1"
                            :min="0"
                            :max="9999999"
                          ></el-input-number>
                        </div>
                        <div class="nums-select">
                          <el-select
                            v-model="formLabelAlign.commisions[index].zd_treat_type"
                            placeholder="请选择提成类型"
                          >
                            <el-option
                              v-for="item in zd_amount"
                              :key="item.label"
                              :label="item.value"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:100px;">
                    <div
                      class="projectSet-edit-list-item"
                      v-if="item.status"
                      style="width:100%;display:flex;align-items: flex-start;justify-content: flex-start;"
                    >
                      <el-popconfirm
                        title="确定删除吗？"
                        @confirm="delcommisions(index)"
                      >
                        <div class="projectSet-edit-list-item-btn" slot="reference">
                          <i class="el-icon-close"></i>
                        </div>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="width:100%;display:flex;align-items: flex-start;justify-content: flex-start;"
              >
                <el-button
                  type="primary"
                  @click="addcommisions"
                  icon="el-icon-circle-plus-outline"
                  >添加提成类型</el-button
                >
              </div>
            </div>
          </div>
          <div class="projectSet-edit-other">
            <div class="projectSet-edit-other-title">项目描述</div>
            <div class="projectSet-edit-other-cont">
              <!-- <vue-ueditor-wrap
                style="z-index:0;"
                v-model="formLabelAlign.remark"
                :config="config"
              /> -->
              <i-quill-editor style="z-index: 0" :content="formLabelAlign.remark" @textChange="handleTextChange"></i-quill-editor>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="cencels">取 消</el-button>
            <el-button v-if="type" type="primary" @click="saves(1)">添加</el-button>
            <el-button v-if="!type" type="primary" @click="saves(0)"
              >保存修改</el-button
            >
          </span>
        </el-dialog>
      </el-col>
    </el-row>

    <el-dialog title="分发" :visible.sync="dialogVisible1" width="90%">
      <div class="projectSet-edit">
        <div class="projectSet-edit-list">
          <div class="projectSet-edit-list-item">
            <span>项目名称：</span>
            <el-input
              placeholder="请输入内容"
              v-model="fromData.name"
              clearable
              disabled
            >
            </el-input>
          </div>
        </div>
        <div class="projectSet-edit-list">
          <div class="projectSet-edit-list-item">
            <span>分发到所有门店：</span>
            <el-switch
              v-model="fromData.all"
              active-text="全部"
              inactive-text="指定"
            >
            </el-switch>
          </div>
        </div>
        <div class="projectSet-edit-list" v-if="!fromData.all">
          <div class="projectSet-edit-list-item">
            <span>请选择分发门店：</span>
            <el-select v-model="fromData.list" multiple placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delivery(0)">取 消</el-button>

        <el-button type="primary" @click="distribute">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import IQuillEditor from '../../components/home/i-quill-editor.vue';

export default {
  // components: { VueUeditorWrap },
  components: { IQuillEditor },
  data() {
    return {
      msg: "",
      config: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "http://35.201.165.105:8000/controller.php",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "./UEditor/",
        zIndex: 3500,
      },
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      dialogVisible1: false,
      userData: [],
      userSearchData: [],
      userDataIndex: "", //下标
      formLabelAlign: {
        remark: ''
      },
      options: [{ id: 0, name: "全部" }], //项目类型
      options1: [], //部门
      options2: [], //工种
      options3: [
        { id: 1, name: "上架" },
        { id: 0, name: "下架" },
        { id: 2, name: "全部" },
      ], //状态
      options3_value: 1,
      zd_amount: [
        { label: 1, value: "元" },
        { label: 2, value: "%" },
      ], //指定类型
      currentPage4: 1,
      value: 0,
      input: "",
      radio: "",
      fromData: {
        id: "",
        name: "",
        all: false,
        list: [],
      },
      commision_status: false,
    };
  },
  methods: {
    /**
     * 富文本内容改变
     */
    handleTextChange(value) {
      // console.log('textChange', value);
      this.formLabelAlign.remark = value ? value : '<p></p>';
    },
    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.getList();
      }
    },
    /* 判断选择提成是否有重复的员工 */
    change_commisions(val) {
      let d = this.formLabelAlign.commisions;
      let len = d.length;
      let num = 0;

      /* 判断有没有重复的 */
      for (let i = 0; i < len; i++) {
        if (val == d[i].worktype_id - 0) {
          num++;
        }
      }

      /* 如果大于2提示选择重复了并清除 */
      if (num > 1) {
        this.$message.error("请不要选择重复的工种~");
        for (let i = 0; i < len; i++) {
          if (val == d[i].worktype_id - 0) {
            d[i].worktype_id = null;
            // break;
          }
        }
      }
    },
    tabType(index) {
      this.tabIndex = index;
    },
    getworktype_name(id, obj) {
      let d = obj;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (d[i].id == id) {
          return d[i].name;
        }
      }
    },
    getworktype() {
      this.$axios({
        href: "/api/app/meiye/projecttype/store",
      }).then((res) => {
        console.log(res);
        this.options = this.options.concat(res.data);
      });

      this.$axios({
        href: "/api/app/meiye/shopdep/store",
      }).then((res) => {
        console.log(res);
        this.options1 = res.data;
      });

      this.$axios({
        href: "/api/app/meiye/worktype/store",
      }).then((res) => {
        console.log(res);
        this.options2 = res.data;
      });
    },
    searchs() {
      let val = this.input;
      //   let status = this.options3_value == 2 ? null : this.options3_value; //2等于全部状态
      // let types = this.value ? this.value : null; //0等于全部类型

      if (this.options3_value == 2 || !this.value || this.value) {
        this.getList();
        return false;
      }

      if (!val) {
        this.$message({
          message: "关键字不能为空",
          type: "warning",
        });
        return false;
      }
      this.getList();
    },
    changes() {
      if (!this.input) {
        this.clears();
        return false;
      }
      this.searchs();
    },
    clears() {
      this.getList();
    },
    addList(obj) {
      let d = JSON.parse(JSON.stringify(obj));
      d.commisions = JSON.stringify(d.commisions);
      this.$axios({
        href: "/api/app/meiye/project/add",
        data: d,
      }).then(() => {
        this.$message({
          type: "success",
          message: "添加项目成功!",
        });
        this.cencels();
        this.getList();
      });
    },
    updateList(obj) {
      let d = JSON.parse(JSON.stringify(obj));
      d.commisions = JSON.stringify(d.commisions);
      this.$axios({
        href: "/api/app/meiye/project/edit",
        data: d,
      }).then(() => {
        this.$message({
          type: "success",
          message: "更新项目成功!",
        });
        this.cencels();
        this.getList();
      });
    },
    delLset(obj) {
      console.log(obj);
      let d = JSON.parse(JSON.stringify(obj));
      d.commisions = JSON.stringify(d.commisions);
      d.status = d.status - 0 ? 0 : 1;
      this.$axios({
        href: "/api/app/meiye/project/edit",
        data: d,
      }).then(() => {
        this.$message({
          type: "success",
          message: d.status ? "上架项目成功!" : "下架项目成功!",
        });
        this.getList();
      });

      // 改成下架
      // this.$axios({
      //   href: "/api/app/meiye/project/del",
      //   data: obj,
      // }).then(() => {
      //   this.getList();
      //   this.$message({
      //     type: "success",
      //     message: "删除项目成功!",
      //   });
      // });
    },
    getList() {
      // input value options3_value
      let status = this.options3_value == 2 ? null : this.options3_value; //2等于全部状态
      let types = this.value ? this.value : null; //0等于全部类型
      this.$axios({
        href: "/api/app/meiye/project/store",
        data: { category_id: types, name: this.input, status: status },
      }).then((res) => {
        console.log('getListRes', res);
        let d = res.data;
        let len = d.length;

        for (let l = 0; l < len; l++) {
          d[l].status = d[l].status - 0 ? "1" : "0";
          d[l].yuyue = d[l].yuyue - 0 ? true : false;
          d[l].member_price_status = d[l].member_price_status - 0 ? true : false;
          d[l].cb_status = d[l].cb_status - 0 ? true : false;
          for (let i = 0; i < d[l].commisions.length; i++) {
            d[l].commisions[i].type = d[l].commisions[i].type - 0 < 2 ? 1 : 2;
            d[l].commisions[i].status = true;
          }
        }
        this.userData = d;
        this.userSearchData = d;
      });
    },
    /* 添加更新 */
    edit(index, obj) {
      this.commision_status = false;
      console.log(obj);
      // console.log(obj);
      this.userDataIndex = "";
      this.type = index;
      if (!index) {
        // this.userData.wxapp = this.userData.wxapp - 0 ? true : false;
        // this.userData.wxapp_wxstatus =
        //   this.userData.wxapp.wxstatus - 0 ? true : false;
        // this.userData.status = this.userData.status ? true : false;
        // this.unified_price_type = this.unified_price_type ? true : false;
        this.formLabelAlign = this.userSearchData[obj.$index];
        this.userDataIndex = obj.$index;
      }
      if (index) {
        this.formLabelAlign = {
          name: "",
          price: "0",
          category_id: "",
          time_long: "",
          remark: "",
          picture: "",
          dep_id: "",
          yuyue: true,
          first_price: "0",
          member_price_status: "",
          member_price: "0",
          cb_status: "",
          cb: "0",
          status: "1",
          commisions: [],
        };
      }
      this.dialogVisible = !this.dialogVisible;
    },
    del(obj) {
      console.log(obj.row);
      let title =
        obj.row.status - 0
          ? "此操作将 [" + obj.row.name + "] 下架该项目, 是否继续?"
          : "此操作将 [" + obj.row.name + "] 上架该项目, 是否继续?";
      this.formLabelAlign = obj.row;
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.saves(obj.row, true);
        })
        .catch(() => {});
    },
    delivery(obj) {
      console.log(obj);
      if (obj) {
        this.fromData = obj.row;
      } else {
        this.fromData = {};
      }
      this.dialogVisible1 = !this.dialogVisible1;
    },
    cencels() {
      this.dialogVisible = !this.dialogVisible;
    },
    saves(obj, type) {
      let len = this.formLabelAlign.commisions.length;

      for (let i = 0; i < len; i++) {
        if (!this.formLabelAlign.commisions[i].worktype_id) {
          this.$message({
            message: "请选择职位/工种",
            type: "warning",
          });
          // break;
          return false;
        }
        this.formLabelAlign.commisions[i].commision_id =
          this.formLabelAlign.commisions[i].commison_id || "0";

        this.formLabelAlign.commisions[i].type =
          this.formLabelAlign.commisions[i].type - 0 > 1 ? 2 : 1;
      }
      console.log(this.formLabelAlign, obj);
      this.formLabelAlign.yuyue = this.formLabelAlign.yuyue ? "1" : "0";
      this.formLabelAlign.member_price_status = this.formLabelAlign.member_price_status ? "1" : "0";
      this.formLabelAlign.cb_status = this.formLabelAlign.cb_status ? "1" : "0";

      if (!this.formLabelAlign.name) {
        this.$message({
          message: "请填写项目名",
          type: "warning",
        });
        return false;
      }
      if (!this.formLabelAlign.category_id) {
        this.$message({
          message: "请选择项目类型",
          type: "warning",
        });
        return false;
      }

      if (!this.formLabelAlign.price) {
        this.$message({
          message: "标准价格不能为0",
          type: "warning",
        });
        return false;
      }
      if (!this.formLabelAlign.first_price) {
        this.$message({
          message: "体验价格不能为0",
          type: "warning",
        });
        return false;
      }
      // if (!this.formLabelAlign.category_id) {
      //   this.$message({
      //     message: "请选择项目类型",
      //     type: "warning",
      //   });
      //   return false;
      // }

      /* 添加 */
      if (obj && !type) {
        this.addList(this.formLabelAlign);
        return false;
      }
      /* 编辑 */
      if (!obj && !type) {
        this.updateList(this.formLabelAlign);
      }
      /* 下架 */
      if (type) {
        // console.log(111111)
        this.delLset(this.formLabelAlign);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
      console.log(file);
    },

    /* 删除提成设置 */
    delcommisions(index) {
      console.log(index);
      // console.log(index, this.userDataIndex,this.userSearchData[this.userDataIndex].commisions[index].commision_id,this.formLabelAlign.commisions);
      if (this.userDataIndex != "") {
        // this.userSearchData[this.userDataIndex].commisions[index].commision_id =
        //   "";
        // this.userSearchData[this.userDataIndex].commisions[
        //   index
        // ].status = false;

        this.userSearchData[this.userDataIndex].commisions.splice(index, 1);
        return false;
      }

      // this.formLabelAlign.commisions[index].commision_id = "";
      // this.formLabelAlign.commisions[index].status = false;
      this.formLabelAlign.commisions.splice(index, 1);
    },
    /* 添加提成设置 */
    addcommisions() {
      if (this.userDataIndex != "") {
        this.userSearchData[this.userDataIndex].commisions.push({
          worktype_id: "",
          commision_id: "0",
          amount: 0,
          type: 1,
          zd_type: 1,
          zd_amount: 0,
          status: true,
        });
        console.log('this.userSearchData[this.userDataIndex]', this.userSearchData[this.userDataIndex]);
        return false;
      }

      // console.log(this.formLabelAlign,this.userDataIndex)
      this.formLabelAlign.commisions.push({
        worktype_id: "",
        commision_id: "0",
        amount: 0, //非指定现金金额或百分比
        type: 1, //非指定现金类型
        zd_type: 1, //指定现金金额或百分比
        zd_amount: 0, //指定现金金额或百分比
        value_type: 1, //非指定储蓄卡类型
        value_amount: 0, //非指定储蓄卡金额或百分比
        zd_value_type: 1, //指定储蓄卡类型
        zd_value_amount: 0, //指定储蓄卡金额或百分比
        treat_type: 1, //非指定疗程提成类型
        treat_amount: 0, //非指定疗程提成金额或百分比
        zd_treat_type: 1, //指定疗程提成类型
        zd_treat_amount: 0, //指定疗程提成金额或百分比
        status: true,
      });

      console.log('this.formLabelAlign', this.formLabelAlign);
    },
    update(file) {
      const isJPG = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG.includes(file.file.type)) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 、WEBP格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      let d = new FormData();
      d.append("image", file.file);
      d.append("token", sessionStorage.getItem("token"));
      this.$axios({
        href: "/api/app/meiye/upload/project",
        data: d,
      }).then((res) => {
        console.log(res, res.data.data.url);
        if (this.userDataIndex == "") {
          this.formLabelAlign.picture = res.data.data.url;
        } else {
          this.userSearchData[this.userDataIndex].picture = res.data.data.url;
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    distribute() {
      this.$message({
        type: "success",
        message: "分发成功!",
      });
      this.dialogVisible1 = !this.dialogVisible1;
    },
  },
  created() {
    this.getList();
    this.getworktype();
    console.log(111111);
  },
};
</script>
<style lang="scss" scoped>
.projectSet {
  width: 100%;
  height: 100%;
  display: flex;

  &-photo {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &-right {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    // background: pink;
    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      &-head {
        width: 100%;
        // min-width: 1200px;
        height: 60px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        overflow: auto;
        // background: red;
        &-list {
          // width: 100%;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            display: inline-block;
            width: 50px;
          }
        }
        &-list:nth-of-type(1) {
          width: 445px;
        }
        &-list:nth-of-type(2) {
          width: 128px;
          margin-left: 10px;
        }
        &-list:nth-of-type(3) {
          width: 128px;
          margin-left: 10px;
          // button {
          //   width: 35%;
          // }
        }
        &-list:nth-of-type(4) {
          width: 100px;
          margin-left: 10px;
        }
        &-list:nth-of-type(5) {
          width: 120px;
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
      &-page {
        width: 100%;
        height: 60px;
        margin-top: 20px;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 300px;
    display: flex;
    // background: red;
    &-display {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-list {
      //  width: 33.33%;
      box-sizing: border-box;
      min-height: 500px;

      &-item {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        // justify-content: center;
        position: relative;
        span {
          display: inline-block;
          width: 70px;
          text-align: left;
        }
        &-input {
          width: 320px;
          height: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &-btn {
          position: absolute;
          cursor: pointer;
          background: #8088a8;
          color: #fff;
          top: 20px;
          right: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &-item-special {
        width: 150%;
        &-input {
          width: 516px;
        }
      }
      &-item-display {
        width: flex-start;
      }
    }

    &-list:nth-of-type(1) {
      padding: 24px;
      width: 224px;
      margin: 0 28.5px;
      // background: pink;
      min-height: 360px;
    }

    &-list:nth-of-type(2) {
      padding-top: 24px;
      min-width: 406px;
      min-height: 360px;
      margin-right: 57.5px;
      // background: blue;
    }
    &-list:nth-of-type(3) {
      padding-top: 24px;
      min-width: 415px;
      min-height: 360px;
      // background: pink;
    }
    &-other {
      width: 100%;
      min-height: 100px;
      &-title {
        width: 100%;
        height: 60px;
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: Bold;
        text-align: left;
        color: #000000;
        line-height: 60px;
      }
      &-cont {
        width: 100%;
        min-height: 60px;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
        transition: all 0.5s;
        &-btn {
          width: 100px;
          height: 40px;
          bottom: 10px;
          position: absolute;
          left: 50%;
          margin-left: -50px;
          // background: red;
        }
      }
      &-cont-none {
        width: 100%;
        // min-height: 230px;
        padding: 10px;
        // overflow: hidden;
        box-sizing: border-box;
        position: relative;
      }
      &-cont-open {
        min-height: 176px;
        position: relative;
      }
    }
  }

  // &-edit {
  //   width: 100%;
  //   min-height: 500px;
  //   &-list {
  //     width: 100%;
  //     min-height: 40px;

  //     margin-bottom: 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;
  //     img {
  //       width: 148px;
  //       height: 148px;
  //       margin-left: 15px;
  //     }
  //     &-item {
  //       width: 100%;
  //       height: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-start;

  //       margin-right: 10px;
  //       span {
  //         display: inline-block;
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-start;
  //         width: 120px;

  //       }
  //       &-btn {
  //         cursor: pointer;
  //         width: 25px;
  //         height: 60px;
  //         margin: auto;
  //         background: #fff
  //           url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Del_Red.png)
  //           left center/24px no-repeat;
  //         border: none;
  //         outline: none;
  //         box-sizing: border-box;
  //         padding: 0;
  //         margin-left: 5px;
  //       }
  //     }
  //     &-item:last-child {
  //       margin-right: 0px;
  //     }
  //   }
  // }
}
</style>

<style moudle>
.projectSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.projectSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.projectSet-right-cont-head {
  transition: all 0.5s;
}

.select {
  width: 80%;
}
.input {
  width: 76%;
}
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}

/* @media screen and (max-width: 1280px) {
  .projectSet-right-cont-head {
    transition: all 0.5s !important;
    height: 120px;
    display: block !important;
  }
  .projectSet-right-cont-head-list {
    background: red;
    display: block !important;
    width: 100% !important;
    float: left !important;
    margin: 0 !important;
  }
  .projectSet-right-cont-head .projectSet-right-cont-head-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .projectSet-right-cont-head .projectSet-right-cont-head-list:nth-child(2),
  .projectSet-right-cont-head .projectSet-right-cont-head-list:nth-child(4) {
    margin: 0px !important;
  }
  .projectSet-right-cont-head .projectSet-right-cont-head-list:nth-child(4) {
    width: 45% !important;
    margin-left: 10px !important;
  }
} */
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
.edui-editor {
  z-index: 0 !important;
}
/* .avatar-uploader {
  font-size: 20px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-right: 10px;
  border-radius: 10px;
  margin-left: 10px;
}
.avatar-uploader:hover {
  border: 1px dashed #409eff;
} */
.el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radius .el-input__inner {
  border-radius: 20px;
}
.zd_amount-box {
  width: 100%;
  /* height: 176px; */
  overflow: hidden;
  position: relative;
}

.zd_amount {
  margin-bottom: 10px;
  display: flex;
  border-bottom: 1px dashed #eee;
}
.zd_amount-box-open {
  width: 100%;
  height: auto;
  overflow: auto;
  position: relative;
}
.nums {
  max-width: 100%;
  width: 200px;
  height: auto;
  display: flex;
  position: relative;
}
.nums-input {
  width: 60%;
  height: 100%;
}
.nums-input .el-input-number {
  width: 100%;
}
.nums-select {
  width: 40%;
  height: 100%;
}
.el-dialog .el-dialog__footer {
  text-align: center;
}
</style>
